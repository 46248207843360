.btn-default{
    background: #b82d63;
    color: #fff;
    border-radius: 10px;
    width: 155px;
    cursor: pointer;
}

.btn-default:hover{
    color: #b82d63;
    background: #fff;
    border:1px solid #b82d63;
}
