.btnModalConfirm{
  background: #25D366;
  color: #fff;
  border-radius: 5px;
  width: 155px;
  border:1px solid #dddddd;
}

.btnModalRefuse{
  background: #FF4D4D;
  color: #fff;
  border-radius: 5px;
  width: 155px;
  border:1px solid #dddddd;
}

.btnModalConfirm:hover{
  background: #fff;
  color:#25D366;
  border:solid 1px ;
  border-color: #25D366;
  box-shadow: 0px 5px 5px lightgray;
}

.btnModalRefuse:hover{
  background: #fff;
  color:#FF4D4D;
  border:solid 1px ;
  border-color: #FF4D4D;
  box-shadow: 0px 5px 5px lightgray;
}