.tutorial-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espaçamento entre imagens */
    justify-content: center;
  }
  
  .tutorial-image {
    max-width: 45%; /* Para garantir que duas imagens fiquem lado a lado */
    height: auto;
    object-fit: cover;
    border-radius: 5px;
  }
  
  
  .h1-tutorial {
    font-size: 2rem;
    color: #be376a;
    margin-bottom: 20px;
  }

  .h2-tutorial {
    font-size: 1.5rem;
    color: #333;
    margin-top: 30px;
  }
  
  p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  ul {
    list-style: none;
    padding: 0;
    text-align: left;
    font-size: 1.1rem;
  }
  
  ul li {
    background: #f8f8f8;
    margin: 8px 0;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
  }
  
  .tutorial-step {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .tutorial-step:hover {
    transform: scale(1.02);
  }
  
  .tutorial-step p {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .tutorial-image {
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }

  .voltar-btn {
    background-color: #be376a; /* Cor do Lup */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    display: inline-block;
  }
  
  .voltar-btn:hover {
    background-color: #a12c5b; /* Um tom mais escuro para hover */
  }
  
  .button-div{
    display: flex;
    justify-content: start;
    padding-bottom: 30px;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .tutorial-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.4rem;
    }
  
    p {
      font-size: 1rem;
    }

    .tutorial-image {
      max-width: 250px;
    }
  }
  