.container-anuncio {
  padding: 3rem 5rem;
}
.btn-anuncio {
  position: absolute;
  right: 5rem;
}
.btn-anuncio button {
  padding: 10px 20px;
  color: #fff;
  background-color: #03aec0;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
}
.imagem-anuncio {
  width: 200px;
  height: 200px;
}
.imagem-anuncio img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.main-anuncio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.main-anuncio h1 {
  margin: 15px;
  color: #be376a;
  font-weight: 400;
  text-align: center;
}
.main-anuncio p {
  text-align: center;
  padding: 0 16px;
}
.container-tabs {
  box-shadow: 0px 0px 5px 0px;
  margin-top: 30px;
}

.btn-whatsapp {
  background-color: #4caf50; /* Verde para combinar com o WhatsApp */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  text-transform: uppercase; 
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
}

.btn-whatsapp:hover {
  background-color: #45a049; 
  transform: scale(1.05); 
}

.btn-whatsapp:active {
  transform: scale(0.98);
}

.btn-whatsapp:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666; 
}
.avaliacoes-container {
  margin-top: 30px;
  padding: 20px;
  border-top: 2px solid #eee;
}

.avaliacoes-container h2 {
  font-size: 1.8rem;
  color: #BE3E70;
  text-align: center;
  margin-bottom: 20px;
}

.lista-avaliacoes {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.avaliacao-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
}

.avaliacao-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avaliacao-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.avaliacao-nome {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.star-icon {
  color: #ccc;
  font-size: 18px;
  margin-right: 2px;
}

.star-icon.filled {
  color: #BE3E70;
}

.avaliacao-comentario {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}



@media only screen and (max-width: 800px) {
  .container-anuncio {
    padding: 3rem 0.3rem;
  }
  .btn-anuncio {
    position: relative;
    right: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
